import React from "react";
import Media from "react-media";
import { MdClose } from "@react-icons/all-files/md/MdClose";

import { Flex, Header, Text } from "Atoms";
import { BrowseOptions } from "Providers";
import { TableMode } from "Types";
import { getOnlineHeaderMessage, getOnlineBrowseOnlyInfo } from "OnlineUtils";
import { OnlineHeaderDesktop, OnlineHeaderMobile } from "./components";

type Props = {
    restaurant: any;
    shouldOnlyBrowse: Record<BrowseOptions, boolean>;
    table: TableMode | null;
};

const CloseToastBtn = ({ closeToast, ...props }: any) => {
    return <MdClose onClick={closeToast} {...props} />;
};

export const OnlineHeaderMessageContainer: React.FC<Props> = React.memo(({ restaurant, shouldOnlyBrowse, table }) => {
    const {
        contactInformation,
        settings: {
            onlineSettings: { customClosedMessage, customOpenMessage }
        }
    } = restaurant;

    const systemMessageToast = (systemMessage: any, qoplaMessage: any, toast: any) => {
        if (!systemMessage.enabled) {
            toast.dismiss("systemMessage");
        }
        if (systemMessage.enabled && !!qoplaMessage.title && !!qoplaMessage.text) {
            toast(
                <Flex alignItems="center" flexDirection="column">
                    <Header as="h3" margin={0}>
                        {qoplaMessage.title}
                    </Header>
                    <Text>{qoplaMessage.text}</Text>
                </Flex>,
                {
                    draggable: false,
                    closeButton: <CloseToastBtn size={32} />,
                    position: "top-center",
                    autoClose: false,
                    closeOnClick: false,
                    delay: 0,
                    toastId: "systemMessage"
                }
            );
        }
    };

    const browseModeMsg = getOnlineBrowseOnlyInfo(shouldOnlyBrowse, customClosedMessage);
    const headerMsg = getOnlineHeaderMessage(browseModeMsg, customOpenMessage);

    const addressInfo = `${contactInformation.addressLine}, ${contactInformation.postCode}, ${contactInformation.city}`;
    const props = { restaurant, headerMsg, addressInfo, customOpenMessage, systemMessageToast, table };

    return (
        <Media queries={{ small: { maxWidth: 991 } }}>
            {matches => (matches.small ? <OnlineHeaderMobile {...props} /> : <OnlineHeaderDesktop {...props} />)}
        </Media>
    );
});
