import React from "react";

import { Header, Box, Radio, Stack, Flex } from "Atoms";
import * as Types from "Types";
import { useOnline } from "Providers";
import { LanguageHeader } from "Components";
import { OnlineText } from "OnlineComponents";
import { formatModificationPrice } from "Utils";
import { RadioGroup } from "Molecules";
import { useLanguage } from "LanguageProvider";

interface Props {
    idx: number;
    modificationCategory: {
        name: keyof Types.Modifications;
        options: any[];
        isRequired?: boolean;
    };
    handleSelectedModification: Function;
    selectedModifications: Types.Modifications;
    menuProduct: Types.MenuProduct;
}

const ModificationHeader = ({ modificationName, isOnlineExpress }: any) => {
    const { translate } = useLanguage();

    return (
        <Header
            as="h4"
            fontSize={isOnlineExpress ? "2xl" : "xl"}
            backgroundColor="gray.300"
            paddingY={2}
            paddingX={4}
            rounded="md"
        >
            {translate(modificationName)}
        </Header>
    );
};

export const CustomerModificationCategory: React.FC<Props> = ({
    idx,
    modificationCategory,
    handleSelectedModification,
    selectedModifications,
    menuProduct
}) => {
    const { isOnlineExpress } = useOnline();
    if (!modificationCategory.options.length || modificationCategory.options.length === 1) return null;

    const selectedValue = JSON.stringify(selectedModifications[modificationCategory.name]);
    return (
        <Box id={idx.toString()} marginY={8}>
            <div>
                <ModificationHeader modificationName={modificationCategory.name} isOnlineExpress={isOnlineExpress} />
                {modificationCategory.isRequired && <LanguageHeader tid="mandatory" as="h4" size="sm" />}
            </div>
            <Stack stretch={4} mt={3}>
                <RadioGroup
                    onChange={event => {
                        handleSelectedModification(modificationCategory.name, JSON.parse(event.target.value));
                    }}
                    value={JSON.stringify(selectedModifications[modificationCategory.name])}
                >
                    {modificationCategory.options.map((mod: Types.Modification, i) => {
                        let priceText = formatModificationPrice(mod.addonPrice, mod.price, false);

                        const defaultPrice = menuProduct.price
                            ? menuProduct.price
                            : menuProduct.refProduct.defaultPrice;
                        const modsToUse = menuProduct.modifications
                            ? menuProduct.modifications
                            : menuProduct.refProduct.modifications;

                        if (
                            defaultPrice === 0 &&
                            priceText &&
                            !(
                                modsToUse &&
                                modsToUse.sizes &&
                                (modsToUse.sizes as any).some((item: any) => item.addonPrice > 0) &&
                                modsToUse &&
                                modsToUse.flavours &&
                                (modsToUse.flavours as any).some((item: any) => item.addonPrice > 0)
                            )
                        ) {
                            priceText = priceText.replace("+", "");
                        }

                        return (
                            <Radio
                                size="lg"
                                key={mod.name}
                                value={JSON.stringify(mod)}
                                checked={selectedValue === JSON.stringify(mod)}
                                isFullWidth
                            >
                                <Flex h="auto" w="full" justify="space-between" align="center">
                                    <OnlineText as="span">{mod.name}</OnlineText>
                                    {priceText && <OnlineText as="span">{priceText}</OnlineText>}
                                </Flex>
                            </Radio>
                        );
                    })}
                </RadioGroup>
            </Stack>
        </Box>
    );
};
