import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { isMobile } from "react-device-detect";
import { IoIosRemove } from "@react-icons/all-files/io/IoIosRemove";
import { IoIosAdd } from "@react-icons/all-files/io/IoIosAdd";

import { Modal, ModalHeader, ModalBody, ModalActions, ModalCloseBtn, Progress } from "Molecules";
import { Text, Image, Flex, Box, Textarea, NewDivider, Header, useHasImageLoaded, Fade } from "Atoms";
import { useModifications, useEventCallback, useOrientation } from "Hooks";
import { usePos, upsertAddons, AddonOperation, useOnline } from "Providers";
import { useLanguage } from "LanguageProvider";
import { AllergenAndContentInfo, CustomerBundleCategories, CustomerModificationCategory } from "./components";
import { OnlineAddonsGroups, AddonGroupsLevel, OnlineStrikeThroughPrice } from "OnlineComponents";
import { OnlineIconButton, OnlineText, OnlineButton } from "OnlineComponents";
import { getOnlineModalStyles, isOnlineProductIncrementDisabled } from "OnlineUtils";
import { getModificationLengths, calculateBundleAddonPrice } from "TempUtils";
import {
    getMenuProductModificationsToUse,
    getTotalAddonsPrice,
    isCartProductIncrementDisabled,
    shouldDisplayStockStatusText
} from "Utils";
import {
    Addon,
    BundleProductCategory,
    CartProduct,
    MenuProduct,
    SelectedBundleProductItem,
    OnlineProduct
} from "Types";
import {
    calculateFixedDiscountPrice,
    swedishPriceText,
    calculateProductPrice,
    getPriceText,
    getPriceAndDiscountText
} from "../../../../../admin/components/poses/pos/utils/priceUtils";
import { LanguageText } from "Components";
import { buildBundleProductCats, getRequiredModifications, isAllRequiredModificationsSelected } from "./utils";
import { orderWindowSocketStore, useOnlineStore, useOrderWindowsStore, useQoplaStore } from "Stores";
import {
    findFixedSubscriptionDiscount,
    tempBundleProductOrderProduct as createNewBundleCartProduct
} from "../../../../../admin/components/poses/shared/utils/cartController";
import "./style.scss";

const getDisabledStatus = (bundleCartProduct: CartProduct | null, modsArrLength: number, allAreSelected: boolean) => {
    if (!!bundleCartProduct?.orderProduct?.selectedBundleProductItems) {
        return !bundleCartProduct.orderProduct.selectedBundleProductItems!.every(bundleItem => {
            if (bundleItem.modificationsToUse && bundleItem.modifications) {
                const requiredModifications = getRequiredModifications(bundleItem.modificationsToUse);
                const _isAllRequiredModificationsSelected = requiredModifications
                    ? isAllRequiredModificationsSelected(requiredModifications, bundleItem.modifications)
                    : true;
                return _isAllRequiredModificationsSelected;
            } else {
                return !!bundleItem.refProductId;
            }
        });
    } else {
        return modsArrLength > 0 && !allAreSelected;
    }
};

const anySelected = (
    bundleCartProduct: CartProduct | null,
    modsArrLength: number,
    somethingIsSelected: boolean,
    selectedAddons: Addon[]
) => {
    if (!!bundleCartProduct) {
        return (
            bundleCartProduct.orderProduct.selectedBundleProductItems!.some(bundleItem => bundleItem.refProductId) ||
            selectedAddons.length > 0
        );
    } else {
        return modsArrLength > 0 && somethingIsSelected;
    }
};

const getPercent = (
    bundleProductCategories: BundleProductCategory[],
    selectedBundleProductItems: SelectedBundleProductItem[] | undefined | null
) => {
    const totalLimit = bundleProductCategories.reduce((acc, next) => (acc = acc + next.limit), 0);
    const totalSelectedItems = selectedBundleProductItems?.filter((item: any) => !!item.refProductId)?.length ?? 0;

    return (totalSelectedItems / totalLimit) * 100;
};

export const OnlineProductModal: React.FC<{}> = () => {
    const { type } = useOrientation();

    const { nickname } = orderWindowSocketStore();

    const { restaurantProductModalState, setRestaurantProductModalState } = useOnlineStore();
    const isModalOpen = !!restaurantProductModalState;
    const onlineProduct = restaurantProductModalState?.onlineProduct as OnlineProduct;
    const menuProductCategoryId = restaurantProductModalState?.menuProductCategoryId;
    const cartProduct = restaurantProductModalState?.cartProduct;
    const shopId = restaurantProductModalState?.shopId;
    const isUpsell = !!restaurantProductModalState?.isUpsell;
    const fixedDiscount = restaurantProductModalState?.fixedDiscount;

    const refProduct = onlineProduct?.refProduct || cartProduct?.menuProduct?.refProduct;
    const refBundleProduct = onlineProduct?.refBundleProduct || cartProduct?.menuBundleProduct?.refBundleProduct;
    const refOrBundleProduct = refProduct ? refProduct : refBundleProduct;

    const hasImageLoaded = useHasImageLoaded({ src: refOrBundleProduct?.imageUrl });

    const bundleProductCategories = refBundleProduct?.bundleProductCategories || [];

    const {
        setProductAmount,
        allActiveFixedDiscounts,
        addOrderProductToCart,
        ProductStockManager,
        productsStock,
        selectedBundleCartProduct,
        setSelectedBundleCartProduct,
        upsertBundleCartProductToCart
    } = useOrderWindowsStore();
    const setBundleCartProduct = setSelectedBundleCartProduct;
    const bundleCartProduct = selectedBundleCartProduct;

    const { refProducts, editProduct, getRemainingStockQuantity } = usePos();
    const { isOnlineExpress } = useOnline();
    const { translate } = useLanguage();
    const { selectedShop } = useQoplaStore();

    // Set the local state when the bundle changes
    useEffect(() => {
        if (cartProduct) {
            setBundleCartProduct(cartProduct);
        } else if (!!refBundleProduct) {
            const temp = createNewBundleCartProduct(
                onlineProduct.menuBundleProduct,
                shopId,
                menuProductCategoryId,
                1,
                fixedDiscount,
                nickname
            ) as CartProduct;

            setBundleCartProduct(temp);
        } else {
            setBundleCartProduct(null);
        }
    }, [refBundleProduct]);

    const cart = cartProduct || bundleCartProduct;
    const [selectedAddons, setSelectedAddons] = useState<Addon[]>(cart ? cart.orderProduct.addons : []);
    const [commentToRestaurant, setCommentToRestaurant] = useState<string | null>(
        !!cartProduct?.orderProduct?.comment ? cartProduct.orderProduct.comment : ""
    );
    const [percent, setPercent] = useState(0);
    const [productQuantity, setProductQuantity] = useState(1);

    // Since we always render this modal in OnlineOrder.tsx
    // This useEffect will keep track when to set states to default values
    // and when to populate them
    useEffect(() => {
        if (isModalOpen) {
            setSelectedAddons(cart?.orderProduct?.addons ?? []);
            setPercent(
                getPercent(bundleProductCategories, bundleCartProduct?.orderProduct?.selectedBundleProductItems) ?? 0
            );
            setCommentToRestaurant(cart?.orderProduct?.comment ?? null);
            cartProduct && setProductQuantity(cartProduct.orderProduct.quantity);
        } else {
            setSelectedAddons([]);
            setPercent(0);
            setCommentToRestaurant(null);
            setProductQuantity(1);
            setProductAmount("");
        }
    }, [isModalOpen]);

    const selectedBundleId = bundleCartProduct?.id;

    const productFixedDiscount = cartProduct?.fixedDiscount || fixedDiscount;
    const hasFixedDiscount: boolean = productFixedDiscount?.isFixedDiscountActive ?? false;
    const _fixedDiscount = findFixedSubscriptionDiscount(allActiveFixedDiscounts, productFixedDiscount);

    const clearState = () => {
        setRestaurantProductModalState && setRestaurantProductModalState(null);
        percent === 100 && setPercent(0);

        onSelectModification("flavours", null);
        onSelectModification("sizes", null);
        setSelectedBundleCartProduct(null);
    };

    const closeModal = (wasClosedFromEsc: boolean = false) => {
        if (wasClosedFromEsc && bundleCartProduct) {
            ProductStockManager.onUpdateRemainingStockQuantity(bundleCartProduct, true);
            setSelectedBundleCartProduct(null);
        }
        clearState();
    };

    const modifications = (refProduct && getMenuProductModificationsToUse(onlineProduct)) || {};

    const oldMods = get(cartProduct, "orderProduct.modifications", {});
    const { onSelectModification, modificationsArray, selectedModifications, allAreSelected, somethingIsSelected } =
        useModifications(modifications, oldMods, restaurantProductModalState);

    useEffect(() => {
        if (refProduct && !cart) {
            setProductAmount("1");
        } else if (cart && cart.orderProduct.quantity) {
            setProductQuantity(cart.orderProduct.quantity);
            setProductAmount(String(cart.orderProduct.quantity));
        }

        // preselect modifications
        const { sizes, flavours } = getModificationLengths(modifications);
        if (sizes === 1) {
            onSelectModification("sizes", modifications.sizes[0]);
        }
        if (flavours === 1) {
            onSelectModification("flavours", modifications.flavours[0]);
        }
    }, [isModalOpen]);

    useEffect(() => {
        if (!!bundleCartProduct?.orderProduct?.selectedBundleProductItems && percent !== 100) {
            setPercent(() =>
                getPercent(bundleProductCategories, bundleCartProduct.orderProduct.selectedBundleProductItems!)
            );
        }
    }, [bundleCartProduct ? bundleCartProduct.orderProduct.selectedBundleProductItems : ""]);

    useEffect(() => {
        setProductAmount(String(productQuantity));
    }, [productQuantity]);

    const saveButtonDisabled = getDisabledStatus(bundleCartProduct, modificationsArray.length, allAreSelected);

    const internalStockLeft = getRemainingStockQuantity(refProduct?.id || refBundleProduct?.id);

    const getUnfinishedCategory = (
        bundleCartProduct: CartProduct | null,
        modsArrLength: number,
        allAreSelected: boolean
    ) => {
        // Get categories sorted by sortOrder
        const categories = [...buildBundleProductCats(bundleProductCategories, refProducts)].sort(
            (a, b) => a.sortOrder - b.sortOrder
        );
        // Initialize array to store indices of unfinished categories
        let unfinishedCategories: number[] = [];
        if (!!bundleCartProduct?.orderProduct?.selectedBundleProductItems) {
            bundleCartProduct.orderProduct.selectedBundleProductItems!.forEach(bundleItem => {
                const getCategoryIndex = categories.findIndex(cat => cat.id === bundleItem.bundleProductCategoryId);
                // Check if modifications are present and if all required modifications are selected
                if (bundleItem.modificationsToUse && bundleItem.modifications) {
                    const requiredModifications = getRequiredModifications(bundleItem.modificationsToUse);
                    const _isAllRequiredModificationsSelected =
                        requiredModifications.length > 0 &&
                        isAllRequiredModificationsSelected(requiredModifications, bundleItem.modifications);
                    // If not all required modifications are selected, add category index to unfinishedCategories
                    if (!_isAllRequiredModificationsSelected) {
                        unfinishedCategories.push(getCategoryIndex);
                    }
                } else if (!bundleItem.refProductId) {
                    // If refProductId is not present, add category index to unfinishedCategories
                    unfinishedCategories.push(getCategoryIndex);
                }
            });
        } else if (modsArrLength > 0 && !allAreSelected) {
            // If selected bundle product items do not exist but there are modifications and not all are selected
            const getRequiredMod = Object.entries(selectedModifications).map(([key, value]) => {
                if (modifications[key].length > 0) {
                    return {
                        key,
                        value
                    };
                }
            });
            // Get index of first modification that is not selected
            const getIndex = getRequiredMod?.findIndex(mod => mod && mod.value === undefined);
            unfinishedCategories.push(getIndex);
        }
        // Return string representation of the first index in unfinishedCategories
        return unfinishedCategories[0]?.toString();
    };

    const scrollAndBlink = () => {
        const getId = getUnfinishedCategory(bundleCartProduct, modificationsArray.length, allAreSelected);
        const minDiv = document.getElementById(getId);
        minDiv?.scrollIntoView({ behavior: "smooth", block: "center" });
        minDiv?.classList.add("blinking");
        setTimeout(() => {
            minDiv?.classList.remove("blinking");
        }, 1500);
    };

    const clickOrEnterToAddProduct = () => {
        if (saveButtonDisabled) {
            scrollAndBlink();
            return;
        }
        if (!onlineProduct) {
            return;
        }

        const isEditingCartProduct = !!cartProduct;

        if (!bundleCartProduct) {
            if (isEditingCartProduct) {
                const cartProductToEdit = {
                    ...cartProduct,
                    orderProduct: {
                        ...cartProduct!.orderProduct,
                        modifications: selectedModifications,
                        addons: selectedAddons,
                        quantity: productQuantity,
                        comment: commentToRestaurant
                    }
                };
                editProduct(cartProductToEdit);
            } else {
                addOrderProductToCart(
                    onlineProduct.menuProduct as MenuProduct,
                    shopId!,
                    menuProductCategoryId!,
                    selectedModifications as any,
                    false,
                    commentToRestaurant,
                    selectedAddons,
                    isUpsell,
                    !!_fixedDiscount ? productFixedDiscount : undefined,
                    onlineProduct?.isStockTracked,
                    nickname!
                );
            }
        } else {
            upsertBundleCartProductToCart(
                bundleCartProduct,
                productQuantity,
                !!_fixedDiscount ? productFixedDiscount : undefined,
                commentToRestaurant,
                selectedAddons,
                !!cartProduct
            );
        }

        closeModal();
    };

    useEventCallback({
        eventName: "keydown",
        callback: (e: KeyboardEvent) => {
            // esc handler
            if (e.keyCode === 27) {
                closeModal();
            }

            if (e.key === "Enter") {
                e.preventDefault();
                if (!saveButtonDisabled) {
                    clickOrEnterToAddProduct();
                }
            }
        }
    });

    // Early fragment return
    if (!restaurantProductModalState || !refOrBundleProduct) {
        return <></>;
    }

    const handleIncrementProduct = () => {
        setProductQuantity((prevState: number) => {
            const newQuantity = prevState + 1;
            ProductStockManager.onUpdateRemainingStockQuantity({
                ...bundleCartProduct,
                orderProduct: {
                    ...bundleCartProduct?.orderProduct,
                    quantity: newQuantity
                }
            });

            return newQuantity;
        });
    };

    const handleDecrementProduct = () => {
        setProductQuantity((prevState: number) => {
            const newQuantity = prevState - 1;
            ProductStockManager.onUpdateRemainingStockQuantity({
                ...bundleCartProduct,
                orderProduct: {
                    ...bundleCartProduct?.orderProduct,
                    quantity: newQuantity
                }
            });

            return newQuantity;
        });
    };

    let priceText: string = "";
    let discountText: string = "";
    let buttonTxtMargin: string = !hasFixedDiscount || isMobile ? "" : "5.25rem";
    let isFree: boolean = false;

    const calculatedSelectedOptionsPrice = anySelected(
        bundleCartProduct,
        modificationsArray.length,
        somethingIsSelected,
        selectedAddons
    );

    if (calculatedSelectedOptionsPrice || refProduct) {
        const selectedBundleItems = bundleCartProduct && bundleCartProduct.orderProduct.selectedBundleProductItems;
        const productPrice = calculateProductPrice(
            onlineProduct,
            productQuantity,
            selectedModifications as any,
            selectedBundleItems as any,
            selectedAddons as any
        );

        priceText = `${productPrice.totalPrice} kr`;

        if (hasFixedDiscount) {
            let addonPrice: number = 0;
            if (!!refBundleProduct) {
                addonPrice = calculateBundleAddonPrice(selectedBundleItems);
                addonPrice += getTotalAddonsPrice(selectedAddons);
            } else {
                addonPrice = getTotalAddonsPrice(selectedAddons);
            }

            const totalPrice: number = productPrice.totalPrice - addonPrice;
            const calculatedDiscountPrice: number = calculateFixedDiscountPrice(_fixedDiscount, totalPrice);
            const discountedPriceWithAddons: number = calculatedDiscountPrice + addonPrice;
            discountText = swedishPriceText(discountedPriceWithAddons);
        }
    } else {
        if (!hasFixedDiscount) {
            priceText = getPriceText(onlineProduct, refProducts);
        } else {
            const priceAndDiscount = getPriceAndDiscountText(onlineProduct, refProducts, _fixedDiscount);
            priceText = priceAndDiscount.price;
            discountText = priceAndDiscount.discountPrice;
            isFree = priceAndDiscount.isFree;
        }
    }
    discountText = isFree ? translate("free") : discountText;

    const { modalBodyStyles, modalStyles } = getOnlineModalStyles(isOnlineExpress, type);

    // Since cartProduct and onlineProduct's structure is different
    // we have two util functions to check if we can increment either of them
    const isCartProductPresent = !!cart;
    const isIncrementBtnDisabled = isCartProductPresent
        ? isCartProductIncrementDisabled(cart!, productsStock, "remainingQuantity", productQuantity)
        : isOnlineProductIncrementDisabled(onlineProduct, productsStock, productQuantity);

    return (
        <Modal open closeOnEscape rounded="md" isScrolling overrideOverflow {...modalStyles}>
            <ModalHeader px={4}>
                <Header as="h3" size="xl" mb="0">
                    {refOrBundleProduct.name}
                </Header>
                {!cartProduct && (
                    <ModalCloseBtn
                        size={isOnlineExpress ? "touch" : "md"}
                        onClick={() => {
                            closeModal(true);
                        }}
                    />
                )}
                {!!selectedBundleId && (
                    <Box bg="white" position="sticky" px={2} py={4} top={-1} zIndex={10}>
                        <Progress size={isOnlineExpress ? "lg" : "sm"} percent={percent} checkpoints={[]} />
                    </Box>
                )}
            </ModalHeader>
            <ModalBody padding="0 1rem 1rem 1rem" {...modalBodyStyles}>
                <Flex direction="column" align="center" mb={8} h="auto">
                    <Fade in={!!refOrBundleProduct.imageUrl && hasImageLoaded}>
                        <Image
                            src={refOrBundleProduct.imageUrl}
                            ignoreFallback
                            objectFit="cover"
                            maxH={["20rem", "30rem"]}
                            rounded="md"
                        />
                    </Fade>
                    {refOrBundleProduct.description && refOrBundleProduct.description !== "" && (
                        <OnlineText marginTop={6} width="100%" whiteSpace="pre-line">
                            {refOrBundleProduct.description}
                        </OnlineText>
                    )}
                </Flex>

                <AllergenAndContentInfo
                    allergens={refOrBundleProduct.allergens}
                    contents={refOrBundleProduct.contents}
                />

                {bundleCartProduct && (
                    <CustomerBundleCategories
                        refProducts={refProducts}
                        bundleProductCategories={bundleProductCategories}
                        bundleCartProduct={bundleCartProduct}
                        setBundleCartProduct={setBundleCartProduct}
                    />
                )}
                {modificationsArray.length > 0 && (
                    <Box marginY={6}>
                        {modificationsArray.map((modificationCategory, idx) => (
                            <CustomerModificationCategory
                                idx={idx}
                                key={modificationCategory.name}
                                menuProduct={onlineProduct as MenuProduct}
                                modificationCategory={modificationCategory as any}
                                handleSelectedModification={onSelectModification}
                                selectedModifications={selectedModifications}
                            />
                        ))}
                    </Box>
                )}
                <OnlineAddonsGroups
                    showHeader
                    level={AddonGroupsLevel.Product}
                    productId={refOrBundleProduct.id}
                    // PLEASE let's decide whether to :
                    // 1. include displayName in the actual product
                    // 2. call a function to create the display name from the product here
                    productName={
                        (refOrBundleProduct as any).displayName
                            ? (refOrBundleProduct as any).displayName
                            : refOrBundleProduct.name
                    }
                    onAddAddon={addon => {
                        setSelectedAddons(currAddons => upsertAddons(currAddons, addon, AddonOperation.ADD));
                    }}
                    onIncrementAddon={addon => {
                        setSelectedAddons(currAddons => upsertAddons(currAddons, addon, AddonOperation.INCREMENT));
                    }}
                    onDecrementAddon={removeAddon => {
                        setSelectedAddons(currAddons =>
                            upsertAddons(currAddons, removeAddon, AddonOperation.DECREMENT)
                        );
                    }}
                    onResetAddons={resetAddon => {
                        setSelectedAddons(currAddons => upsertAddons(currAddons, resetAddon, AddonOperation.RESET));
                    }}
                    selectedAddons={selectedAddons}
                />
                {selectedShop?.settings.onlineSettings.productCommentsEnabled && (
                    <>
                        <NewDivider marginY={3} color="gray.400" marginX={16} width="auto" />
                        <Text textAlign="center" fontSize="lg">
                            {translate("commentFor")} {refOrBundleProduct.name}
                        </Text>
                        <Textarea
                            fullWidth
                            fontSize="lg"
                            value={commentToRestaurant ?? ""}
                            onChange={(e: any) => setCommentToRestaurant(e.target.value)}
                            placeholder={translate("writeYourCommentHere")}
                            minHeight={40}
                            resize="none"
                            maxLength={255}
                        />
                    </>
                )}
            </ModalBody>

            <ModalActions px={4}>
                <Flex align="end">
                    {!cartProduct && (
                        <Box>
                            {shouldDisplayStockStatusText(internalStockLeft) && (
                                <LanguageText
                                    tid="fewLeft"
                                    fontSize="sm"
                                    textTransform="uppercase"
                                    letterSpacing="1px"
                                    fontWeight="bold"
                                    color="teal.500"
                                />
                            )}

                            <Flex align="center" mr={6}>
                                <OnlineIconButton
                                    icon={IoIosRemove}
                                    fontSize="20px"
                                    h={12}
                                    w={12}
                                    isDisabled={productQuantity === 1}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        handleDecrementProduct();
                                    }}
                                />
                                <OnlineText as="span" userSelect="none" mx={4}>
                                    {productQuantity}
                                </OnlineText>
                                <OnlineIconButton
                                    icon={IoIosAdd}
                                    fontSize="20px"
                                    h={12}
                                    w={12}
                                    isDisabled={isIncrementBtnDisabled}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        handleIncrementProduct();
                                    }}
                                />
                            </Flex>
                        </Box>
                    )}
                    <Box flex="1">
                        <OnlineButton
                            fullWidth
                            backgroundColor="newPrimary"
                            color="newPrimaryFont"
                            themeColor="green"
                            size="lg"
                            onClick={clickOrEnterToAddProduct}
                        >
                            <Flex height="auto" width="100%">
                                {!hasFixedDiscount ? (
                                    <OnlineText as="span" fontSize="lg">
                                        {priceText}
                                    </OnlineText>
                                ) : (
                                    <>
                                        <OnlineStrikeThroughPrice colour="newPrimaryFont">
                                            <OnlineText as="span" fontSize="lg">
                                                {priceText}
                                            </OnlineText>
                                        </OnlineStrikeThroughPrice>
                                        <OnlineText as="span" fontSize="lg" ml={1}>
                                            {discountText}
                                        </OnlineText>
                                    </>
                                )}
                                <OnlineText as="span" flex="1" fontSize="lg" marginRight={buttonTxtMargin}>
                                    {" "}
                                    {translate(cartProduct ? "update" : "addTo")}
                                </OnlineText>
                            </Flex>
                        </OnlineButton>
                    </Box>
                </Flex>
            </ModalActions>
        </Modal>
    );
};
