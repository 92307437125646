import React, { useEffect, useState } from "react";
import { capitalize, get } from "lodash";

import { Flex, Header, Box } from "Atoms";
import { usePos, BundleItemOperation, useOnline, useQopla } from "Providers";
import { useLanguage } from "LanguageProvider";
import { SelectedBundleProductItem, Modification, CartProduct, Modifications } from "Types";
import { Accordion, AccordionItem, AccordionContent } from "Organisms";
import { getSingleModification, getModificationLengths } from "TempUtils";
import { BundleItemAccordionContent } from "./BundleItemAccordionContent";
import {
    getBundleProductItems,
    getActiveBundleItemsIndexes,
    getSelectedBundleItemAmountPerCat,
    findAddedBundleItemInSelectedBundleProductItems
} from "../utils";
import { findLowestModificationAddonPrice, modificationsHasAddonPrice } from "../../../../../../admin/utils/TextFormat";
import { BundleItemAccordionTitle } from "./BundleItemAccordionTitle";
import { ExtendedBundleProductCategory, AvailableBundleItem } from "OnlineTypes";
import { getBundleCategorySelectedBundleProductItems } from "Utils";
import { useOrderWindowsStore } from "Stores/orderWindowsStore";

/**@deprecated This is only re-exported here from "OnlineTypes".  Please use "OnlineTypes" directly */
export type { AvailableBundleItem };
interface Props {
    idx: string;
    bundleProductCategory: ExtendedBundleProductCategory;
    bundleCartProduct: CartProduct;
    setBundleCartProduct: (cartProduct: CartProduct | null) => void;
    categoryBundleItems: SelectedBundleProductItem[] | undefined;
}

export const CustomerBundleCategory: React.FC<Props> = ({
    idx,
    bundleProductCategory,
    bundleCartProduct,
    setBundleCartProduct
}) => {
    const { selectedBundleProductItems } = bundleCartProduct.orderProduct;
    const { hideFromStockIds, addons, getRemainingStockQuantity } = usePos();

    const { CartManager, productsStock, ProductStockManager } = useOrderWindowsStore();

    const { updateBundleCartProductWithSelectedBundleItems, updateBundleItemWithModification } = CartManager;
    const { translate } = useLanguage();
    const { isOnlineExpress } = useOnline();
    const { selectedCompany } = useQopla();

    const [activeIndexes, setActiveIndexes] = useState<number[]>(() =>
        getActiveBundleItemsIndexes(selectedBundleProductItems!, bundleProductCategory, addons)
    );

    const isSingleProductCategory = bundleProductCategory.limit === 1;

    useEffect(() => {
        const indexes = getActiveBundleItemsIndexes(selectedBundleProductItems!, bundleProductCategory, addons);

        setActiveIndexes(indexes);
    }, [selectedBundleProductItems]);

    const handleSetActiveIndexes = (newIndex: number) => {
        setActiveIndexes(currIndexes => {
            const isOpen = currIndexes.includes(newIndex);
            if (isOpen) {
                return currIndexes.filter(index => index !== newIndex);
            }
            return currIndexes.concat(newIndex);
        });
    };

    const onRemoveBundleItem = (
        bundleItem: AvailableBundleItem,
        bundleItemAccordionIndex: number,
        closeAccordionItem: boolean
    ) => {
        closeAccordionItem && handleSetActiveIndexes(bundleItemAccordionIndex);

        const updatedBundleCartProduct = updateBundleCartProductWithSelectedBundleItems(
            bundleCartProduct,
            bundleItem,
            bundleProductCategory,
            null,
            true,
            null,
            BundleItemOperation.REMOVE
        );
        setBundleCartProduct(updatedBundleCartProduct);

        ProductStockManager.onUpdateRemainingStockQuantity(updatedBundleCartProduct);
    };

    const getBundleItemIndex = (operation: BundleItemOperation.REPLACE | BundleItemOperation.ADD) => {
        let newIndex = selectedBundleProductItems!.findIndex(item => {
            if (item.bundleProductCategoryId === bundleProductCategory.id) {
                return operation === BundleItemOperation.ADD ? !item.refProductId : true;
            }
        });

        if (operation === BundleItemOperation.REPLACE) {
            return newIndex;
        }

        return newIndex;
    };

    const bundleItemsPerCatAmount = getSelectedBundleItemAmountPerCat(
        selectedBundleProductItems!,
        bundleProductCategory.id
    );

    const onSelectModification = (
        modificationKey: string,
        modificationToAdd: Modification,
        bundleItemIndex: number // We can have multiple of same product.
    ) => {
        const _newModification = {
            [modificationKey]: modificationToAdd
        };

        const updatedBundleCartProduct = updateBundleItemWithModification(
            bundleCartProduct,
            bundleItemIndex,
            _newModification
        );

        setBundleCartProduct(updatedBundleCartProduct);
    };

    const onAccordionClick = (productIndex: number, bundleItem: SelectedBundleProductItem, limitReached: boolean) => {
        const operation = limitReached ? BundleItemOperation.REPLACE : BundleItemOperation.ADD;
        //@ts-ignore
        const bundleItemAddons = addons[bundleItem.id];
        const singleMods = !!bundleItem.modifications ? getSingleModification(bundleItem.modificationsToUse) : null;

        let updatedBundleCartProduct = null;
        if (!bundleItem.modifications && !bundleItemAddons) {
            updatedBundleCartProduct = updateBundleCartProductWithSelectedBundleItems(
                bundleCartProduct,
                bundleItem,
                bundleProductCategory,
                {},
                true,
                null,
                operation
            );
            setBundleCartProduct(updatedBundleCartProduct);
        }

        if (!!bundleItem.modifications || !!bundleItemAddons) {
            const index = getBundleItemIndex(operation);
            updatedBundleCartProduct = updateBundleCartProductWithSelectedBundleItems(
                bundleCartProduct,
                bundleItem,
                bundleProductCategory,
                {},
                true,
                null,
                operation,
                index
            );
            handleSetActiveIndexes(productIndex);
            setBundleCartProduct(updatedBundleCartProduct);
        }

        if (!!singleMods) {
            updatedBundleCartProduct = updateBundleCartProductWithSelectedBundleItems(
                bundleCartProduct,
                bundleItem,
                bundleProductCategory,
                singleMods,
                true,
                null,
                operation
            );
            setBundleCartProduct(updatedBundleCartProduct);
        }

        // ProductStockManager.onUpdateRemainingStockQuantity(updatedBundleCartProduct);
    };

    const bundleProductItems = getBundleProductItems(
        bundleProductCategory.refProducts,
        bundleCartProduct,
        bundleProductCategory.id,
        productsStock
    );

    const availableBundleItems =
        bundleProductItems.length > 1
            ? bundleProductItems.filter(product => !(hideFromStockIds && hideFromStockIds.has(product.id)))
            : bundleProductItems;

    const isBundleProductCategoryLimitReached = bundleItemsPerCatAmount === bundleProductCategory.limit;
    const isMultipleBundleProductCategory = bundleProductCategory.limit > 1;
    const isAccordionDisabled = isBundleProductCategoryLimitReached && isMultipleBundleProductCategory;

    if (availableBundleItems.length === 1) {
        const modsToUse = get(availableBundleItems, ["0", "modificationsToUse"], {});
        const refId = get(availableBundleItems, ["0", "id"], null);
        const modLength = getModificationLengths(modsToUse);
        // check if addons
        const itemAddons = addons[refId];
        if (modLength.sizes <= 1 && modLength.flavours <= 1 && !itemAddons) {
            return null;
        }
    }

    const bundleCategorySelectedBundleProductItems = getBundleCategorySelectedBundleProductItems(
        selectedBundleProductItems || [],
        bundleProductCategory.id
    );

    const isSubscriptionProductFree =
        bundleCartProduct?.fixedDiscount?.subscriptionProductMeta?.percentageDiscount === 100;

    const isSimpleModificationsAndNoAddons = (bundleItemId: string, modificationsToUse: Modifications | null) => {
        if (!modificationsToUse) {
            return false;
        }
        const modLength = getModificationLengths(modificationsToUse);
        const bundleItemAddons = !!addons[bundleItemId];
        const hasSimpleMods = modLength.sizes <= 1 && modLength.flavours <= 1;
        const onlyOneSelection = bundleProductCategory.limit === 1;
        return !bundleItemAddons && hasSimpleMods && onlyOneSelection;
    };

    return (
        <Box id={idx} key={bundleProductCategory.id + bundleProductCategory.index} marginY={8} marginX={1}>
            <Flex direction="column" backgroundColor="gray.300" rounded="md" py={2} px={4} mb={1}>
                <Header as="h4" fontWeight="normal" fontSize="xl" mb={2}>
                    {capitalize(bundleProductCategory.name)} - {translate("choose")} {bundleProductCategory.limit}{" "}
                    {translate(isMultipleBundleProductCategory ? "alternatives" : "alternative")}
                </Header>
            </Flex>

            <Accordion multi activeIndexes={activeIndexes}>
                {availableBundleItems.map((availableBundleItem, index) => {
                    const selectedBundleItem = findAddedBundleItemInSelectedBundleProductItems(
                        availableBundleItem.id,
                        bundleCategorySelectedBundleProductItems
                    );
                    let priceText = availableBundleItem.priceText;

                    if (!priceText && !!availableBundleItem.modificationsToUse) {
                        const lowestModPrice = findLowestModificationAddonPrice(availableBundleItem.modificationsToUse);
                        priceText = lowestModPrice > 0 ? `${translate("from")} +${lowestModPrice} kr` : "";
                    }

                    let subscriptionModificationIsFree = false;
                    let hasSimpleModsAndNoAddons = false;
                    if (!!availableBundleItem.modificationsToUse) {
                        const hasModificationPrice = modificationsHasAddonPrice(availableBundleItem.modificationsToUse);
                        subscriptionModificationIsFree = hasModificationPrice && isSubscriptionProductFree;
                        hasSimpleModsAndNoAddons = isSimpleModificationsAndNoAddons(
                            availableBundleItem.id,
                            availableBundleItem.modificationsToUse
                        );
                    }
                    const bundleItemStock = getRemainingStockQuantity(availableBundleItem.id);

                    return (
                        <AccordionItem key={index} isDisabled={isAccordionDisabled}>
                            <BundleItemAccordionTitle
                                bundleCartProduct={bundleCartProduct}
                                isOnlineExpress={isOnlineExpress}
                                bundleItem={availableBundleItem}
                                onClick={() => {
                                    if (!selectedBundleItem && !availableBundleItem.outOfStock) {
                                        onAccordionClick(
                                            index,
                                            availableBundleItem as any,
                                            isBundleProductCategoryLimitReached
                                        );
                                    }
                                }}
                                onBundleItemIconClick={() =>
                                    onAccordionClick(
                                        index,
                                        availableBundleItem as any,
                                        isBundleProductCategoryLimitReached
                                    )
                                }
                                priceText={priceText}
                                translate={translate}
                                selectedBundleProductItems={selectedBundleProductItems}
                                isMultiBundleCat={isMultipleBundleProductCategory}
                                selectedBundleItem={selectedBundleItem}
                                catLimitReached={isBundleProductCategoryLimitReached}
                                bundleProductCategory={bundleProductCategory}
                                bundleItemStock={bundleItemStock}
                                setBundleCartProduct={setBundleCartProduct}
                                subscriptionModificationIsFree={subscriptionModificationIsFree}
                            />
                            {!hasSimpleModsAndNoAddons && (
                                <AccordionContent>
                                    <BundleItemAccordionContent
                                        bundleCartProduct={bundleCartProduct}
                                        bundleItem={availableBundleItem}
                                        selectedBundleProductItems={selectedBundleProductItems}
                                        onAddNewBundleItem={() => {
                                            onAccordionClick(
                                                index,
                                                availableBundleItem as any,
                                                isBundleProductCategoryLimitReached
                                            );
                                        }}
                                        onRemoveBundleItem={onRemoveBundleItem}
                                        onSelectModification={onSelectModification}
                                        bundleItemAccordionIndex={index}
                                        isBundleCategoryLimitReached={isBundleProductCategoryLimitReached}
                                        shouldShowQuantityIndicator={isSingleProductCategory}
                                        setBundleCartProduct={setBundleCartProduct}
                                    />
                                </AccordionContent>
                            )}
                        </AccordionItem>
                    );
                })}
            </Accordion>
        </Box>
    );
};
